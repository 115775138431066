@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Audiowide&family=Comfortaa&family=Concert+One&family=Cookie&family=Gwendolyn&family=Libre+Caslon+Text:ital@0;1&family=Montserrat:wght@300&family=Open+Sans&family=Orbitron:wght@500&family=Playfair+Display:wght@500&family=Poiret+One&family=Righteous&family=Shadows+Into+Light&family=Special+Elite&family=Syncopate&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Kelly+Slab&family=Yeseva+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Kanit:wght@600&display=swap");
body,
html {
  height: 100%;
  overflow: auto;
}

.footer {
  bottom: 0;
  height: 2em;
  left: 0;
  position: relative;
  display: none;
}

body {
  font-family: "Roboto", sans-serif;
  background-color: #f7f7f7;
  transition: all 0.3s ease-in-out;
  /*position: relative;
  padding-bottom: 50px;*/
}

/*orokorrak*/
label {
  font-size: 13px;
  margin-bottom: 0;
}

.card.login {
  width: 275px;
  margin: 125px auto 0 auto;
  background-color: rgba(241, 241, 241, 0.39);
}

.card.login .card-header {
  text-align: center;
}

.card.login button {
  padding: 10px;
  border-radius: 5px;
  margin-top: 15px;
}

.login-logo {
  /*margin: 35px auto 10px auto;
  height: 75px;
  width: 191px;
  float: none;
  background-image: url(images/logo.png);
  background-position: left;
  background-repeat: no-repeat;
  transition: 0.3s ease all;
  background-size: cover;*/
}

.card-header {
  background-color: initial;
  border-bottom-width: 0;
  padding-bottom: 3px;
}

.k-button-group .k-button:active,
.k-button-group .k-button.k-state-active,
.k-button-group .k-button.k-state-selected,
.k-button-group > input[type=radio]:checked + .k-button,
.k-button-group > input[type=checkbox]:checked + .k-button {
  border-color: #00a8ff;
  color: #ffffff;
  background-color: #00a8ff;
  background-image: linear-gradient(rgba(255, 138, 88, 0.2), rgba(255, 138, 88, 0));
  box-shadow: inset 0 2px 2px 0 rgba(0, 0, 0, 0.06);
}

.k-upload .k-dropzone {
  padding: 3px;
}

.k-upload .k-dropzone {
  background-color: transparent;
}

.k-button.k-upload-button {
  padding: 0;
}

ul.k-upload-files.k-reset.ng-star-inserted {
  background-color: whitesmoke;
}

.card-header + .card-body {
  padding: 0 1rem 0.5rem;
}

.form-control {
  height: calc(1.8rem + 1px);
  font-size: 14px;
}

.k-textbox,
textarea.k-textarea {
  width: 100% !important;
}

kendo-textbox.k-textbox .k-input,
.k-textbox.form-control {
  height: calc(1.8rem + 1px);
  padding: 0.5rem 0.75rem;
}

.btn-success {
  background-color: #8bc34a;
  border-color: #8bc34a;
}

.btn-primary {
  background-color: #00a8ff;
  border-color: #00a8ff;
}

.btn-danger {
  background-color: #f16a72;
  border-color: #f16a72;
}

::selection {
  background: #a1cff7;
  /* WebKit/Blink Browsers */
}

::-moz-selection {
  background: #a1cff7;
  /* Gecko Browsers */
}

a,
a:hover {
  color: #00a8ff;
}

h3 {
  font-size: 15px;
  color: #00a8ff;
  overflow: hidden;
  text-transform: uppercase;
  padding: 0 0 5px 0;
  border-bottom: solid 1px #00a8ff;
  font-family: "Roboto", sans-serif;
}

textarea.k-textarea {
  width: 100% !important;
}

.modal-header .close {
  padding: 5px;
  outline: 0;
}

.k-grid td.k-state-selected,
.k-grid tr.k-state-selected > td {
  background-color: rgba(34, 196, 196, 0.45);
}

.k-checkbox:checked {
  border-color: #00a8ff;
  background-color: #00a8ff;
}

.k-checkbox:indeterminate,
.k-checkbox.k-state-indeterminate {
  color: #00a8ff;
}

kendo-textbox.k-textbox {
  width: 100%;
  height: auto;
}

kendo-textbox.k-textbox .k-input {
  padding: 0.5rem 0.75rem;
  height: 2.25rem;
}

.k-button,
.k-button:hover {
  background-image: none;
}

.k-numerictextbox {
  width: auto !important;
}

.form-group {
  margin-bottom: 5px;
}

kendo-textbox.k-textbox .k-input,
.k-textbox.form-control {
  height: calc(1.8rem + 1px);
  padding: 0.5rem 0.75rem;
}

.k-button-group .k-button:hover,
.k-button-group .k-button.k-state-hover,
.k-button-group .k-button:active,
.k-button-group .k-button.k-state-active {
  z-index: 0;
}

.k-list .k-item.k-state-selected,
.k-list-optionlabel.k-state-selected {
  background-color: #00a8ff;
}

.k-list .k-item.k-state-selected:hover,
.k-list-optionlabel.k-state-selected:hover {
  background-color: #00a8ff;
  opacity: 0.9;
}

.k-calendar .k-state-selected .k-link {
  background-color: #00a8ff;
}

.k-calendar .k-state-selected:hover .k-link,
.k-calendar .k-state-selected.k-state-hover .k-link {
  background-color: #00a8ff;
  opacity: 0.9;
}

.k-calendar .k-footer .k-nav-today,
.k-calendar .k-calendar-header .k-today {
  color: #00a8ff;
}

.k-calendar .k-calendar-navigation li:hover,
.k-calendar .k-calendar-navigation li.k-state-hover {
  color: #00a8ff;
}

.k-widget.form-control {
  padding: 0;
  border: 0;
}

.form-control {
  height: calc(1.8rem + 1px);
  font-size: 14px;
}

.k-dialog-buttongroup {
  justify-content: center;
}

.k-button.k-upload-button {
  padding: 0px 6px;
}

.k-textarea {
  width: 100%;
}

.k-dropdown .k-dropdown-wrap,
.k-dropdowntree .k-dropdown-wrap {
  background-image: none;
  background: transparent;
}

.k-dropdown,
.k-dropdowntree {
  width: 100%;
}

.k-grid tbody td {
  white-space: nowrap;
  line-height: 10px;
  padding: 8px 12px;
}

.k-grid .k-grid-content td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.k-dialog-titlebar {
  background-color: #00a8ff;
}

* {
  scrollbar-color: #202d39 #7c7c7c;
  scrollbar-width: thin;
}

alert {
  position: fixed;
  top: 50px;
  z-index: 10;
  left: 0px;
  right: 0px;
}

/*orokorrak amaiera*/
/*login*/
.login-container {
  margin: 0 50px 0 0;
}

.menu-zabalduta .login-container {
  margin: 0 250px 0 0;
}

.login-bg {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  background: #21b8db;
  background: linear-gradient(0deg, #21b8db 0%, #2274c3 100%);
  background-image: url("https://www.realsociedad.eus/Content/img/club/facilities/Cesped-de-Anoeta-4.jpg");
  /*background-image: url("https://c.pxhere.com/photos/e3/3f/the_ball_stadion_football_the_pitch_grass_game_sport_match-1089676.jpg!d");*/
  background-size: cover;
}

.card.login {
  width: 275px;
  margin: 125px auto 0;
  background-color: rgba(241, 241, 241, 0.39);
  backdrop-filter: blur(4px);
}

/*login amaiera*/
/*botoiak*/
.btn {
  border-radius: 0;
  padding: 3px 8px;
  font-size: 13px;
}

.btn-editar {
  background-color: #36a884;
  color: #ffffff;
}

.btn-nuevo {
  background-color: #8bc34a;
  color: #ffffff;
}

.btn-eliminar {
  background-color: #f16a72;
  color: #ffffff;
}

.btn-copiar {
  background-color: #22c4c4;
  color: #ffffff;
}

/*botoiak amaiera*/
/*scroll bereziak*/
*::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

*::-webkit-scrollbar {
  height: 8px;
  background-color: #f5f5f5;
}

*::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #cdcdcd;
}

*::-webkit-scrollbar {
  width: 10px;
}

/* Track */
*::-webkit-scrollbar-track {
  background: #253341;
}

/* Handle */
*::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
*::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/*scroll bereziak bukaera*/
/*tabs*/
.k-tabstrip-items .k-item,
a,
a:hover {
  color: #00a8ff;
}

/*tabs amaiera*/
/*switch*/
.k-switch-on:hover .k-switch-container,
.k-switch-on.k-state-hover .k-switch-container {
  color: #ffffff;
  background-color: #00a8ff;
}

.k-switch-on .k-switch-container {
  color: #ffffff;
  background-color: #00a8ff;
}

.k-switch-container {
  width: 4em;
}

.k-switch-handle {
  width: 2em;
  height: 2em;
}

.k-switch {
  border-radius: 4em;
  width: 4em;
  margin-right: 5px;
}

.k-switch-on .k-switch-handle {
  left: calc(100% - 2em);
}

.k-switch-label-on,
.k-switch-label-off {
  max-width: 100%;
}

.k-switch-label-on {
  left: 3px;
}

.k-switch-label-off {
  right: 3px;
}

/*switch amaiera*/
.barratop {
  transition: 0.3s ease all;
  background-color: #253341;
  left: 60px;
  border-radius: 0;
  position: fixed;
  top: 0;
  right: 0;
  height: 56px;
  padding: 0;
  z-index: 5;
  display: none;
}

.barratop h2 {
  margin: 0 0 0 20px;
  color: #f7f7f7;
  float: left;
  font-size: 20px;
}

/*MENU LATERAL*/
.barramenu {
  background-color: transparent;
  width: 60px;
  padding: 0 0 40px 0;
  flex-flow: column;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
  overflow-y: auto;
  -webkit-transition: 0.3s ease all;
  transition: 0.3s ease all;
  z-index: 5;
}

.barramenu .nav {
  display: none;
}

.barramenu .logotipoa {
  display: none;
}

.barramenu .logotipoa {
  margin: 8px 0 10px 5px;
  height: 40px;
  width: 37px;
  float: none;
  background-image: url(images/logo.png);
  background-repeat: no-repeat;
  transition: 0.3s ease all;
  background-size: cover;
}

.barramenu .nav-item .nav-link {
  color: #cad3dd;
}

.barramenu .nav-item.link-active a.nav-link {
  color: #00a8ff;
  background-color: rgba(30, 30, 30, 0.35);
}

.barramenu h3 {
  display: none;
  overflow: hidden;
  clear: both;
  background-color: transparent;
  color: #fff;
  margin: 15px 12px 15px 11px;
  text-transform: inherit;
  color: #cad3dd;
  font-weight: 300;
}

.barramenu a {
  visibility: hidden;
  width: 230px;
  color: #cad3dd;
  font-size: 13px;
}

.barramenu .beheko-minimenu a.conf:before,
.barramenu > .nav > li > a:before {
  width: 26px;
  opacity: 0.99;
  font-style: normal;
  font-weight: 900;
  display: inline-block;
  font-family: Font Awesome\5 Free;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  visibility: visible;
  font-size: 20px !important;
  margin-right: 15px;
  transition: all 0.5s;
}

.barramenu.menu-zabalduta ul.beheko-minimenu,
.barramenu:hover ul.beheko-minimenu,
ul.beheko-minimenu li {
  width: 230px;
}

.nav > li > a:focus,
.nav > li > a:hover {
  background-color: rgba(30, 30, 30, 0.35);
  box-shadow: 0 4px 4px 0px rgba(0, 0, 0, 0.5);
  border-right: solid 4px #00a8ff;
  color: #00a8ff !important;
}

ul.nav {
  margin: 0px 0 0 0;
  width: 100%;
}

ul.nav li.nav-item {
  width: 100%;
}

/*toggle despligue*/
.barramenu .menu-zabalera-blokeatu::before {
  width: 26px;
  opacity: 0.99;
  display: inline-block;
  font-style: normal;
  font-weight: 900;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
  font-family: "Font Awesome 5 Free";
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  visibility: visible;
  width: auto;
  font-size: 15px !important;
  color: #c1cdd9;
  margin: -1px 0 0 -3px !important;
  padding: 0 !important;
}

.barramenu .menu-zabalera-blokeatu {
  display: none;
  width: 25px;
  height: 25px;
  border-radius: 50px;
  right: 0;
  position: absolute;
  margin: 10px 10px 0 0;
  padding: 0px 0 0 5px !important;
  border: solid 3px #c1cdd9;
  top: 0;
  cursor: pointer;
  display: none !important;
}

.barramenu.menu-zabalduta .menu-zabalera-blokeatu,
.barramenu:hover .menu-zabalera-blokeatu {
  display: block;
  line-height: 19px;
}

.barramenu.menu-zabalduta .menu-zabalera-blokeatu::before {
  content: "";
}

/*toggle despliegue amaiera*/
/*dropdown menu*/
.barramenu a.nav-link.dropdown-toggle::after,
.barramenu.menu-zabalduta a.nav-link.dropdown-toggle::after {
  display: inline-block;
  width: 10px;
  height: 10px;
  content: "";
  color: #fff;
  float: right;
  margin-top: 5px;
  border-bottom: solid 3px #fff;
  border-left: solid 3px #fff;
  transform: rotate(-45deg);
  -webkit-transition: 0.5s ease all;
  transition: 0.5s ease all;
  border-right-width: 0;
  border-top-width: 0;
}

.barramenu:hover .nav-item.show a.nav-link.dropdown-toggle::after,
.barramenu.menu-zabalduta .nav-item.show a.nav-link.dropdown-toggle::after {
  transform: rotate(135deg);
}

.show a.nav-link.dropdown-toggle {
  background-color: rgba(30, 30, 30, 0.35);
}

.barramenu .dropdown-menu {
  position: relative !important;
  background-color: transparent;
  -webkit-transition: 0.3s ease all;
  transition: 0.3s ease all;
  transform: translate3d(0px, 0px, 0px) !important;
  float: none !important;
}

.dropdown-item {
  padding: 5px 15px;
  width: 100%;
  display: block;
}

.barramenu .dropdown-menu.show {
  display: none !important;
}

.barramenu:hover .dropdown-menu.show,
.barramenu.menu-zabalduta .dropdown-menu.show {
  display: block !important;
}

/*dropdown menu*/
/*barramenu zabalduta*/
.barramenu:hover,
.barramenu.menu-zabalduta {
  width: 230px;
  background-color: #2a3f54;
}

.barramenu:hover .nav,
.barramenu.menu-zabalduta .nav {
  display: block;
}

.barramenu:hover .logotipoa,
.barramenu.menu-zabalduta .logotipoa {
  display: block;
}

.barramenu.menu-zabalduta .logotipoa,
.barramenu:hover .logotipoa {
  width: 88px;
  border-radius: 0;
}

.barramenu.menu-zabalduta ul.nav,
.barramenu:hover ul.nav {
  margin: 0;
}

.barramenu:hover h3,
.barramenu.menu-zabalduta h3 {
  display: block;
}

.barramenu:hover a,
.barramenu.menu-zabalduta a {
  visibility: visible;
}

.barratop.menu-zabalduta {
  left: 230px;
}

.contenedor.body-content.menu-zabalduta {
  margin: 0px 20px 0 240px;
}

.contenedor.body-content {
  margin: 0px 20px 0 70px;
  transition: 0.3s ease all;
  min-height: calc(100% - 110px);
}

.barramenu .nav > .show > a,
.barramenu .nav > .show > a:hover,
.barramenu .nav > .show > a:focus {
  border-left-color: transparent;
  background-color: rgba(30, 30, 30, 0.35);
}

.barramenu:hover li.dropdown.show > a::after,
.barramenu.menu-zabalduta li.dropdown.show > a::after {
  transform: rotate(135deg);
}

.barramenu:hover li.dropdown > a::after,
.barramenu.menu-zabalduta li.dropdown > a::after {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  color: #fff;
  float: right;
  border-bottom: solid 3px #fff;
  border-left: solid 3px #fff;
  transform: rotate(-45deg);
  -webkit-transition: 0.5s ease all;
  transition: 0.5s ease all;
}

.barramenu ul.dropdown-menu.show {
  background-color: rgba(30, 30, 30, 0.35);
  box-shadow: 0 4px 4px 0px rgba(0, 0, 0, 0.5);
  border-right: solid 4px #24ab95;
  margin: 0;
}

ul.dropdown-menu a.nav-link {
  padding: 4px 0 0 28px;
}

.barramenu ul.dropdown-menu li::before {
  content: "";
  left: 10px;
  margin-top: 6px;
  position: absolute;
  width: 26px;
  opacity: 0.99;
  font-style: normal;
  font-weight: 900;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Font Awesome 5 Free";
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  visibility: visible;
  width: auto;
  font-size: 10px !important;
  margin-right: 15px;
  transition: all 0.5s;
  color: white;
}

li.dropdown.show > a {
  background: #222a31 !important;
}

.barramenu > .nav > li > a.home-nav:before {
  content: "";
}

.barramenu > .nav > li > a.palabras-nav:before {
  content: "";
}

.barramenu > .nav > li > a.audios-nav:before {
  content: "";
}

/*barramenu zabalduta amaiera*/
.beheko-minimenu {
  position: fixed;
  bottom: 0px;
  background-color: #253341;
  margin: 0;
  width: 60px;
  cursor: pointer;
  z-index: 9999;
  transition: 0.3s ease all;
  padding: 0;
}

ul.beheko-minimenu li {
  width: 230px;
}

.barramenu:hover ul.beheko-minimenu,
.barramenu.menu-zabalduta ul.beheko-minimenu {
  width: 230px;
}

.beheko-minimenu a {
  padding: 10px;
}

.beheko-minimenu a:hover {
  color: #00a8ff;
}

.conf-menu {
  display: none;
}

.conf-menu.agertu {
  display: block;
}

.barramenu:hover h3.titulo-menu-configuracion,
.barramenu.menu-zabalduta h3.titulo-menu-configuracion {
  display: none;
}

.barramenu:hover h3.titulo-menu-configuracion.agertu,
.barramenu.menu-zabalduta h3.titulo-menu-configuracion.agertu {
  display: block;
}

.barramenu .beheko-minimenu a:before {
  color: #73879c;
  font-size: 28px !important;
}

.barramenu .beheko-minimenu a.conf:before {
  content: "";
}

ul.beheko-minimenu li {
  list-style-type: none;
}

/*MENU LATERAL AMAIERA*/
/*USERMENU*/
.usermenu {
  margin-right: 8px;
}

.usermenu > li {
  cursor: pointer;
  margin: 0px 1px;
  padding: 0px 6px;
  transition: background-color 0.5s;
}

.usermenu > li:hover {
  background: #0d2032;
  border-radius: 5px;
}

.usermenu-iso > li {
  cursor: pointer;
}

.usermenu > li .dropdown-menu {
  top: 110%;
}

.usermenu li a.nombre-usuario {
  background: none !important;
  line-height: 41px;
}

.listaLenguajeItem {
  padding-left: 10px !important;
  transition: background-color 0.5s;
}

.listaLenguajeItem:hover {
  background-color: #d5e3f3;
}

.listaLenguajeItemSeleccionado {
  padding-left: 10px !important;
  background-color: #acc5df;
}

.circulo-tutorial {
  border-radius: 50%;
  height: 32px;
  width: 32px;
  display: inline-block;
  background-color: #e1e1e1;
  color: #253341;
  text-align: center;
  line-height: 32px;
  font-weight: 900;
}

.circulo-usuario {
  border-radius: 50%;
  height: 32px;
  width: 32px;
  display: inline-block;
  background-color: #00a8ff;
  color: #fff;
  text-align: center;
  line-height: 32px;
}

.dropdown-menu.dropdown-usuario {
  padding: 0;
}

.inner-usuario-nombre-cont {
  background-color: #99bcb1;
  width: 300px;
  padding: 15px;
  color: #ffffff;
}

span.inner-usuario-circulo-usuario {
  border-radius: 50%;
  height: 34px;
  width: 34px;
  display: inline-block;
  background-color: #00a8ff;
  color: #fff;
  text-align: center;
  line-height: 34px;
  margin: 0 10px 0 0;
}

.dropdown-menu.dropdown-usuario.show {
  right: 0;
  left: auto;
}

.inner-usuario-tipo-cont {
  float: left;
  width: 50%;
  text-align: center;
  padding: 10px;
  border-right: solid 1px #99bcb1;
  border-bottom: solid 1px #99bcb1;
}

.inner-usuario-num-cont {
  float: left;
  width: 50%;
  text-align: center;
  padding: 10px;
  border-bottom: solid 1px #99bcb1;
}

.inner-usuario-itxura-cont {
  width: 50%;
  float: left;
  border-bottom: solid 1px #99bcb1;
}

.inner-usuario-titulo {
  display: block;
  color: #9e9e9e;
  font-size: 13px;
  text-align: center;
}

.itxura-aldaketa-argia {
  padding: 5px;
  margin: 10px auto;
  display: block;
  color: #ffffff;
  text-align: center;
  width: 33px;
  height: 33px;
  transition: all 1s;
  border-radius: 100%;
  background-image: linear-gradient(45deg, #ffffff 0%, #e6e6e6 50%, #00c1a0 50%, #00c1a0 100%);
  border: solid 2px #2a3f54;
}

.itxura-aldaketa-iluna {
  padding: 5px;
  margin: 10px auto;
  display: block;
  color: #ffffff;
  text-align: center;
  width: 33px;
  height: 33px;
  transition: all 1s;
  border-radius: 100%;
  background-image: linear-gradient(45deg, #253341 0%, #2a3f54 50%, #00c1a0 50%, #00c1a0 100%);
  border: solid 2px #2a3f54;
}

a#Logout {
  background-color: #2a3f54;
  padding: 5px;
  margin: 10px auto;
  display: block;
  color: #ffffff;
  text-align: center;
  width: 90%;
  transition: all 1s;
}

li.selector-idioma > .idioma-seleccionado {
  background-color: #5cc6fd;
  padding: 5px 8px;
  /*border-radius: 50%;*/
  color: #ffffff;
  display: block;
  margin: 4px 0px 0px 0px;
}

.selector-idioma ul.dropdown-menu {
  min-width: 50px;
}

/*USERMENU AMAIERA*/
.card {
  box-shadow: -8px 12px 18px 0 rgba(25, 42, 70, 0.13);
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  border-radius: 0;
  border-width: 0;
  margin: 0 0 20px 0;
}

.card-body {
  padding: 1rem;
}

.card-header + .card-body {
  padding: 0rem 1rem 1rem 1rem;
}

.card-header {
  background-color: transparent;
  border-bottom-width: 0;
  padding-bottom: 3px;
}

.plegarpanel {
  position: absolute;
  right: 25px;
  top: 5px;
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-top: 5px;
  vertical-align: 0.255em;
  content: "";
  color: #fff;
  float: right;
  border-bottom: solid 3px #2a3f54;
  border-left: solid 3px #2a3f54;
  transform: rotate(135deg);
  -webkit-transition: 0.5s ease all;
  transition: 0.5s ease all;
  cursor: pointer;
}

.card.plegado {
  height: 60px;
  overflow: hidden;
}

.card.plegado .plegarpanel {
  transform: rotate(-45deg);
}

/*GRID*/
.k-grid {
  /*  font-size: 12px;*/
  font-size: 14px;
}

.k-grid.grid-font-10 td,
.k-grid.grid-font-10 th {
  font-size: 12px !important;
}

.modal-body .k-grid .k-group-col,
.modal-body .k-grid .k-hierarchy-col {
  width: 3px !important;
}

kendo-grid.k-widget.k-grid {
  border-width: 0;
  padding-bottom: 5px;
}

.k-grid-header,
kendo-grid-toolbar.k-grid-toolbar.k-header {
  background-color: transparent;
}

.k-grid td,
.k-grid th.k-header.k-filterable {
  border-width: 0;
  cursor: pointer;
}

.k-grid th.k-header.k-filterable span.k-link {
  font-weight: bold;
}

.k-grid-table-wrap tr {
  background-color: rgba(0, 0, 0, 0.04);
}

.k-grid-table-wrap tr.k-alt {
  background-color: initial;
}

.k-grid-aria-root .k-grid-aria-root tr th {
  background: #8ad2d2;
}

.k-grid-aria-root tr:hover td.k-hierarchy-cell .k-grid-aria-root {
  background: #8ad2d2;
}

td.k-hierarchy-cell {
  background: #8ad2d2;
}

.k-pager-numbers .k-link:hover,
.k-pager-numbers .k-link.k-state-hover,
.k-pager-numbers .k-link.k-state-selected {
  color: #18d6b0;
  background-color: rgba(24, 214, 176, 0.13);
}

.k-pager-numbers .k-link {
  color: #18d6b0;
}

.virtualizado.k-grid tbody td {
  white-space: nowrap;
  line-height: 20px;
  padding: 8px 12px;
}

.virtualizado.k-grid .k-grid-content td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

kendo-treelist-list.k-grid-container .k-icon {
  margin: 0 10px 0 0;
}

/*scroll bereziak grid*/
.k-grid-content::-webkit-scrollbar-track {
  background: #ffffff;
}

/*scroll bereziak grid amaiera*/
/*GRID amaiera*/
/*fondo video*/
.bgprincipal {
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  background: #000000;
}

/*.bgprincipal {
  background-image: url(images/bg-onda-01.png);
  background-color: #3c3c3b;
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center right;
}*/
.gipuzkoa-argitu-logo-principal {
  position: absolute;
  width: 220px;
}

#myVideo {
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  opacity: 0.5;
}

/*fondo video amaiera*/
.mapa {
  text-align: center;
  position: absolute;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  padding: 0 0 0 150px;
}

.mapa img {
  height: 800px;
}

/*nube de palabras*/
angular-tag-cloud {
  /*transform: skew(-30deg);*/
  overflow: visible !important;
  margin: 60px auto 0 auto;
  /*border: solid 1px red;*/
}

angular-tag-cloud span {
  color: #ffffff !important;
  /*transform: skew(30deg);*/
  display: block;
}

/*.tooltiptext:hover {
  transform: skew(30deg);
}*/
angular-tag-cloud.custom-css a {
  text-decoration: none;
  color: inherit;
}

angular-tag-cloud.custom-css a:hover {
  color: white;
}

angular-tag-cloud span {
  pointer-events: none;
}

angular-tag-cloud span.w1 {
  font-size: 330% !important;
  /*  font-family: 'Comfortaa', cursive;
  font-family: 'Audiowide', cursive;
  font-family: 'Concert One', cursive;*/
  font-family: "Kanit", sans-serif;
}

angular-tag-cloud span.w2 {
  font-size: 360% !important;
  font-family: "Orbitron", sans-serif;
}

angular-tag-cloud span.w3 {
  font-size: 390% !important;
  font-family: "Work Sans", sans-serif;
}

angular-tag-cloud span.w4 {
  font-size: 410% !important;
  font-family: "Poiret One", cursive;
}

angular-tag-cloud span.w5 {
  font-size: 450% !important;
  font-family: "Montserrat", sans-serif;
}

angular-tag-cloud span.w6 {
  font-size: 480% !important;
  font-family: "Playfair Display", serif;
}

angular-tag-cloud span.w7 {
  font-size: 520% !important;
  font-family: "Shadows Into Light", cursive;
}

angular-tag-cloud span.w8 {
  font-size: 550% !important;
  font-family: "Special Elite", cursive;
}

angular-tag-cloud span.w9 {
  font-size: 580% !important;
  font-family: "Righteous", cursive;
}

angular-tag-cloud span.w10 {
  font-size: 600% !important;
  font-family: "Syncopate", sans-serif;
}

/*nube de palabras amaiera*/
.mapa path {
  stroke-dasharray: 500;
  stroke-dashoffset: 2000;
  animation: dash 20s linear infinite;
}

/*.palabra-actual*/
.out-palabra-actual {
  opacity: 0;
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  background: #000000bd;
  text-align: center;
  pointer-events: none;
}

.cont-palabra-actual {
  margin: 250px auto 0 auto;
}

span.palaba-actual {
  font-size: 80px;
  color: #0b49dc;
  font-family: "Kelly Slab", cursive;
  /*font-family: 'Yeseva One', cursive;*/
}

.animar-palabra-actual {
  opacity: 0;
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  background: rgba(0, 0, 0, 0.85);
  text-align: center;
  pointer-events: none;
  animation-name: transicion;
  animation-duration: 6s;
  animation-iteration-count: 1;
}

@keyframes transicion {
  0% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
/*palabra actual amaiera*/
@keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}
/*letrero*/
marquee.letrero {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 60px;
  background-color: #020a23;
  font-size: 60px;
  font-weight: bold;
  overflow: hidden;
  line-height: 60px;
  text-transform: uppercase;
}

marquee.letrero span {
  margin-right: 15px;
}

.frase1 {
  color: #0bf4e9;
}

.frase2 {
  color: #0b49dc;
}

.frase3 {
  color: #0b49f7;
}

/*letrero amaiera*/
/*pantalla secundaria*/
.pantalla-secundaria {
  /*background-image: url(images/bg-d-01.jpg);*/
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center right;
  animation-name: fondoanimado;
  animation-duration: 20s;
  animation-iteration-count: infinite;
}

@keyframes fondoanimado {
  /*
  0% {
    background-image: url(images/bg-d-01.jpg);
  }

  20% {
    background-image: url(images/bg-d-01.jpg);
  }

  33% {
    background-image: url(images/fondo-diapo-02.png);
  }

  53% {
    background-image: url(images/fondo-diapo-02.png);
  }

  66% {
    background-image: url(images/fondo-diapo-03.png);
  }

  87% {
    background-image: url(images/fondo-diapo-03.png);
  }

  100% {
    background-image: url(images/bg-d-01.jpg);
  }*/
  0% {
    background-image: url(images/fondo-diapo-01.png);
  }
  15% {
    background-image: url(images/fondo-diapo-01.png);
  }
  20% {
    background-image: url(images/fondo-diapo-02.png);
  }
  35% {
    background-image: url(images/fondo-diapo-02.png);
  }
  40% {
    background-image: url(images/fondo-diapo-03.png);
  }
  55% {
    background-image: url(images/fondo-diapo-03.png);
  }
  60% {
    background-image: url(images/fondo-diapo-04.png);
  }
  75% {
    background-image: url(images/fondo-diapo-04.png);
  }
  80% {
    background-image: url(images/fondo-diapo-05.png);
  }
  95% {
    background-image: url(images/fondo-diapo-05.png);
  }
  100% {
    background-image: url(images/fondo-diapo-01.png);
  }
}
.gipuzkoa-argitu-logo {
  position: absolute;
  /*z-index: 10;*/
}

.gipuzkoaargitu {
  position: absolute;
  bottom: 40px;
  background: linear-gradient(to right, #2affe2 0%, #0066ff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 40px;
  z-index: 10;
}

/*pantalla secundaria amaiera*/
/*pantalla nocturna*/
.pantalla-nocturna {
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  background-color: #3c3c3b;
}

.frase-nocturna {
  position: absolute;
  right: 200px;
  left: 200px;
  text-align: center;
  transform: rotate(-4deg);
}

.frase-nocturna span {
  font-family: "Playfair Display", serif;
  background: linear-gradient(to right, #2affe2 0%, #0066ff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 65px;
  font-style: italic;
}

.logo-gipuzkoaondas-nocturna {
  position: absolute;
  right: 0;
  top: 30px;
  background-color: #3c3c3b;
  height: 265px;
}

/*pantalla nocturna amaiera*/
/*Estados*/
#webcam {
  position: absolute;
  right: 50px;
  top: 50px;
  border: solid 1px #00a8ff;
  z-index: 10;
}

.mezua {
  text-align: center;
  background: linear-gradient(to right, #2affe2 0%, #0066ff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 51px;
  font-family: "Libre Caslon Text", serif;
  margin-top: 60px;
}

.mezua-2 {
  background: linear-gradient(to right, #2affe2 0%, #0066ff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 51px;
  font-family: "Libre Caslon Text", serif;
  position: absolute;
  text-align: center;
  left: 0;
  right: 0;
  top: 124px;
}

.mezua-3 {
  background: linear-gradient(to right, #2affe2 0%, #0066ff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 45px;
  font-family: "Libre Caslon Text", serif;
  position: absolute;
  text-align: center;
  left: 0;
  right: 0;
  top: 260px;
  padding: 25px;
  line-height: 55px;
}

.cuenta-atras {
  background: linear-gradient(to right, #2affe2 0%, #0066ff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 120px;
  font-family: "Libre Caslon Text", serif;
  position: absolute;
  text-align: center;
  left: 0;
  right: 0;
  top: 260px;
  padding: 25px;
}

.transcripcion {
  border-top: solid 1px #3c3c3b;
  padding: 25px;
  margin: 25px;
}

.pantalla-tablet-1 {
  background-image: url(images/bg-d-01.jpg);
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center right;
}

.img-tablet-1 {
  max-width: 500px;
  position: absolute;
  top: calc(50vh - 260px);
  left: calc(50vw - 200px);
}

.cont-cambiardidioma {
  position: absolute;
  right: 94px;
  bottom: 50px;
  width: 200px;
  height: 60px;
}

.cont-cambiardidioma button {
  background: #1eb5f0;
  color: #ffffff;
}

/*ecualizador*/
.peak {
  position: absolute;
  top: 50%;
  width: 1em;
  height: 1em;
  background: linear-gradient(to top, #2affe2 0%, #0066ff 100%);
}

.peak1 {
  background: linear-gradient(to bottom, #2affe2 0%, #0066ff 100%);
}

.grad1 {
  animation: play 500.5ms steps(10, end) infinite alternate;
}

.p1 {
  left: 29%;
  transform-origin: 0% 0%;
  transform: rotateX(180deg);
}

.s1 {
  left: 29%;
  top: 50%;
  transform-origin: 0% 50%;
  transform: rotateX(-180deg);
}

.grad2 {
  animation: play 303ms steps(10, end) infinite alternate;
}

.p2 {
  left: 33%;
  transform-origin: 0% 0%;
  transform: rotateX(180deg);
}

.s2 {
  left: 33%;
  top: 50%;
  transform-origin: 0% 50%;
  transform: rotateX(-180deg);
}

.grad3 {
  animation: play 498ms steps(10, end) infinite alternate;
}

.p3 {
  left: 37%;
  transform-origin: 0% 0%;
  transform: rotateX(180deg);
}

.s3 {
  left: 37%;
  top: 50%;
  transform-origin: 0% 50%;
  transform: rotateX(-180deg);
}

.grad4 {
  animation: play 235.5ms steps(10, end) infinite alternate;
}

.p4 {
  left: 41%;
  transform-origin: 0% 0%;
  transform: rotateX(180deg);
}

.s4 {
  left: 41%;
  top: 50%;
  transform-origin: 0% 50%;
  transform: rotateX(-180deg);
}

.grad5 {
  animation: play 398.5ms steps(10, end) infinite alternate;
}

.p5 {
  left: 45%;
  transform-origin: 0% 0%;
  transform: rotateX(180deg);
}

.s5 {
  left: 45%;
  top: 50%;
  transform-origin: 0% 50%;
  transform: rotateX(-180deg);
}

.grad6 {
  animation: play 297ms steps(10, end) infinite alternate;
}

.p6 {
  left: 49%;
  transform-origin: 0% 0%;
  transform: rotateX(180deg);
}

.s6 {
  left: 49%;
  top: 50%;
  transform-origin: 0% 50%;
  transform: rotateX(-180deg);
}

.grad7 {
  animation: play 489.5ms steps(10, end) infinite alternate;
}

.p7 {
  left: 53%;
  transform-origin: 0% 0%;
  transform: rotateX(180deg);
}

.s7 {
  left: 53%;
  top: 50%;
  transform-origin: 0% 50%;
  transform: rotateX(-180deg);
}

.grad8 {
  animation: play 564.5ms steps(10, end) infinite alternate;
}

.p8 {
  left: 57%;
  transform-origin: 0% 0%;
  transform: rotateX(180deg);
}

.s8 {
  left: 57%;
  top: 50%;
  transform-origin: 0% 50%;
  transform: rotateX(-180deg);
}

.grad9 {
  animation: play 297ms steps(10, end) infinite alternate;
}

.p9 {
  left: 61%;
  transform-origin: 0% 0%;
  transform: rotateX(180deg);
}

.s9 {
  left: 61%;
  top: 50%;
  transform-origin: 0% 50%;
  transform: rotateX(-180deg);
}

.grad10 {
  animation: play 211ms steps(10, end) infinite alternate;
}

.p10 {
  left: 65%;
  transform-origin: 0% 0%;
  transform: rotateX(180deg);
}

.s10 {
  left: 65%;
  top: 50%;
  transform-origin: 0% 50%;
  transform: rotateX(-180deg);
}

/* static */
.shadow {
  position: absolute;
  top: 50%;
  width: 100%;
  height: 50%;
  background: linear-gradient(to bottom, black 0%, rgba(44, 44, 44, 0.8) 30%, rgba(56, 56, 56, 0.8) 45%, rgba(59, 59, 59, 0.8) 55%, rgba(56, 56, 56, 0.8) 65%, rgba(43, 43, 43, 0.8) 100%);
}

@keyframes play {
  to {
    height: 8em;
  }
}
/*ecualizador amaiera*/
/*barra defs progreso*/
.barraprogreso-cont {
  position: absolute;
  top: 570px;
  left: 0;
  right: 0;
  text-align: center;
}

.barraprogreso {
  width: 885px;
  height: 30px;
  margin: 0 auto;
  background-color: #2a3f54;
}

.barraprogresoinner {
  animation: tiempo 10s linear infinite;
  height: 30px;
  background: linear-gradient(to right, #2affe2 0%, #0066ff 100%);
  width: 0;
}

.tiemporestante {
  text-align: center;
  background: linear-gradient(to right, #2affe2 0%, #0066ff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 49px;
}

@keyframes tiempo {
  to {
    width: 100%;
  }
}
/*barra defs progress amaiera*/
span.idioma-mini {
  font-size: 10px;
}

/*Estados amaiera*/
/*tablet*/
@media (max-width: 2000px) {
  /*pantalla secundaria tablet*/
  .pantalla-tablet-1 {
    background-image: url(images/bg-d-01.jpg);
    position: fixed;
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center right;
  }

  .gipuzkoa-argitu-logo {
    position: relative;
    width: 17%;
    margin-top: 40px;
  }

  .gipuzkoaargitu {
    position: absolute;
    bottom: 40px;
    background: linear-gradient(to right, #2affe2 0%, #0066ff 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 40px;
  }

  /*pantalla secundaria amaiera tablet*/
}